import React, { useState, useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Tabla from './Tabla';
import SelectLista from './SelectLista';
import { getPrestaciones, getTiposMedico } from './Funciones';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import {validate, format} from 'rut.js';
import Select2 from "./Select2";

const IntervencionQuirurgica = ({
    values,
    nextStep,
    prevStep,
    handleChange,
    handleAgregaPrestacion,
    handleQuitarPrestacion,
    handleAgregaMedico,
    handleQuitarMedico,
}) => {

  const { handleSubmit } = useForm();

  const[listaPrestaciones, setListaPrestaciones] = useState([]);
  const[listaTiposMedico, setListaTiposMedico] = useState([]);
  const [nuevaPrestacion, setNuevaPrestacion] = useState({
    id: '',
    codigo: '',
    porcentaje_cobro: '',
  });
  const [MensajeError, setMensajeError] = useState([]);

  const [nuevoMedico, setNuevoMedico] = useState({
    id: '',
    tipo_medico: '',
    tipo_medico_nombre: '',
    rut_medico: '',
    nombre: '',
    cirujano_anestesista: false,
  });

  const continuar = e => {
    e.preventDefault();
    let mensaje = '';
    let errores = false;

    if(values.prestaciones.length == 0 && values.grupo_medico.length > 0){
      mensaje = 'Debe ingresar al menos una prestación';
      errores =true;
    }

    if(errores){
      Swal.fire(mensaje);
      return false;
    }    
    
    nextStep();
}

  const volver = e => {
      e.preventDefault();
      prevStep();
  }

  const agregarPrestacion = data => {
    let error = false;
    let codigo = ''; 
    let mensaje = '';
    if(
      nuevaPrestacion.id === '' ||
      nuevaPrestacion.codigo === '' ||
      nuevaPrestacion.porcentaje_cobro === '' 
    ){
      error = true;
      mensaje = 'Debe ingresar todos los campos obligatorios';

      Swal.fire(mensaje);
      return false;
    }

    values.prestaciones.map((prestacion) => {
      // if(prestacion.id === nuevaPrestacion.id){
      //   error = true;
      //   codigo = prestacion.codigo;
      //   mensaje = 'Ya existe la prestación con el código ' + codigo;
      // }
      if(prestacion.porcentaje_cobro === '100' && nuevaPrestacion.porcentaje_cobro === '100'){
        error = true;
        mensaje = 'Ya ha seleccionado una prestación al 100%';
      }
    });

    if(error){
      Swal.fire(mensaje)
      return false;
    }
    handleAgregaPrestacion(nuevaPrestacion);
    resetPrestacion();
  }

  const resetPrestacion = () => {
    setNuevaPrestacion({
      id: '',
      codigo: '',
      porcentaje_cobro: '',
      nombre: ''
    });
  }

  const agregarMedico = data => {
    let error = false;
    let rut_medico = ''; 
    let mensaje = '';
    
    if(
      nuevoMedico.rut_medico === '' ||
      nuevoMedico.tipo_medico === ''  
    ){
      error = true;
      mensaje = 'Debe ingresar todos los campos obligatorios';

      Swal.fire(mensaje);
      return false;
    }
    
    // values.grupo_medico.map((medico) => {
    //   if(medico.rut_medico === nuevoMedico.rut_medico){
    //     error = true;
    //     rut_medico = medico.rut_medico;
    //     console.log(rut_medico);
    //     mensaje += ' El rut ' +rut_medico + ' ya se encuentra en la lista';
        
    //   }
      
    // });
    if(!validate(nuevoMedico.rut_medico)){
      setMensajeError({rut_medico: 'El rut ingresado es inválido'});
      return false;
    }else{
      setMensajeError({rut_medico: ''});
    }

    if(error){
      Swal.fire(mensaje);
      return false;
    }

    handleAgregaMedico(nuevoMedico);
    resetMedico();
  }

  const resetMedico = () => {
    setNuevoMedico({
      id: '',
      tipo_medico: '',
      tipo_medico_nombre: '',
      rut_medico: '',
      cirujano_anestesista: false,
      nombre: '',
      cantidad: ''
    });
  }

  const getlistaPrestaciones = () => {
    getPrestaciones({
      filial: values.filial
    })
    .then(json => setListaPrestaciones(json.datos));
  }
  const getlistaTiposMedico = () => {
    getTiposMedico()
    .then(json => setListaTiposMedico(json.datos));
  }

  useEffect(() => {
    getlistaPrestaciones();
    getlistaTiposMedico();
  }, []);

  const handleChangePrestacion = input => e => {
    let valor = e.target ? e.target.value : e.value;
    
    if(input === 'id'){
      buscaPrestacionEnLista(valor);
    }
    setNuevaPrestacion({
			...nuevaPrestacion,
			[input] : valor
		})
  }
  const handleChangeMedico = input => e => {
    let valor = e.target ? e.target.value : e.value;

    if(input === 'tipo_medico'){
      buscaTipoMedicoEnLista(valor);
    }

    if(input === 'cirujano_anestesista'){
      valor = (valor === 'on') ? 1 : 0;
    }

    setNuevoMedico({
			...nuevoMedico,
			[input] : valor
		});

  }

  const buscaPrestacionEnLista = (id)  => {
    
		let datos = listaPrestaciones;
    
		for ( let index = 0; index < datos.length; index++) {
			if( datos[index].id === parseInt(id)) {
				nuevaPrestacion.nombre = datos[index].nombre_prestacion;
				nuevaPrestacion.codigo = datos[index].codigo;
			}
		}
	  return true;
  }

  const buscaTipoMedicoEnLista = (id)  => {
    let tmpTipo=null;
		let datos = listaTiposMedico;
  
		for ( let index = 0; index < datos.length; index++) {
			if( datos[index].id === parseInt(id)) {
				nuevoMedico.tipo_medico_nombre = datos[index].nombre;
			}
		}
	  return true;
  }

  const quitarPrestacion = e => {
    let id = parseInt(e.currentTarget.id);
    Swal.fire({
      title: 'Está seguro de eliminar la prestación seleccionada?',
      text: "no podrá deshacer los cambios!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        handleQuitarPrestacion(id);
        Swal.fire(
          'Eliminado!',
          'La prestación ha sido eliminada de la lista.',
          'success'
        )
      }
    })
  }
  const quitarMedico = e => {
    let id = e.currentTarget.attributes.rut.value;
    
    Swal.fire({
      title: 'Está seguro de eliminar el médico seleccionado?',
      text: "no podrá deshacer los cambios!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        handleQuitarMedico(id);
        Swal.fire(
          'Eliminado!',
          'El médico ha sido eliminado de la lista.',
          'success'
        )
      }
    })
  }

  const columns   = [
    {
      name: 'Código',
      cell: row => (
        <>
          {row.codigo}
        </>
      )	
    },
    
    {
      name: 'Descripción',
      cell: row => (
        <>
          {row.nombre} 
        </>
      )	
    },
    {
      name: 'Porcentaje Cobro',
      cell: row => (
        <>
          {row.porcentaje_cobro + '%'}
        </>
      )	
    },
    {
      name: 'Acción',
      cell: row => (
        <>
          <button 
            className="btn btn-sm btn-danger" 
            onClick={quitarPrestacion}
            title="Eliminar"
            id={row.id}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      )	
    },


  ];
  const modalPrestacion =  (
    <>
      <div className="modal fade" id="ModalPrestaciones" tabIndex={-1} role="dialog" aria-labelledby="ModalPrestacionesLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
              <h5 className="modal-title" id="ModalPrestacionesLabel"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <form onSubmit={handleSubmit(agregarPrestacion)}>
                <div className="form-group">
                  <Select2
                    titulo="Seleccione Prestación" 
                    nombreCampo="id" 
                    name="id"
                    datos={listaPrestaciones} 
                    modal="" 
                    handleChange={handleChangePrestacion('id')} 
                    valor={nuevaPrestacion && nuevaPrestacion.id}
                    obligatorio={true}
                  />
                </div>
                <div className="form-group">
                  <label>Código </label>
                  <input 
                    name="codigo"
                    type="text" 
                    placeholder="código prestacion" 
                    className="form-control"
                    value={nuevaPrestacion && nuevaPrestacion.codigo}
                    onChange={handleChangePrestacion('codigo_prestacion')}
                    readOnly={true}
                    //{...register("rut", {required: true})} 
                  />
                </div>
                <div className="form-group">
                  <label>Descripcion prestacion </label>
                  <input 
                    name="nombre"
                    type="text" 
                    placeholder="descripcion de la prestacion" 
                    className="form-control"
                    value={nuevaPrestacion && nuevaPrestacion.nombre}
                    onChange={handleChangePrestacion('descripcion')}
                    readOnly={true}
                    //{...register("rut", {required: true})} 
                  />
                </div>
                <div className="form-group">
                  <label>Porcentaje de cobro <span style={{color:"red"}}>*</span></label>
                  <select
                    name="porcentaje_cobro"
                    className="form-control"
                    value={nuevaPrestacion && nuevaPrestacion.porcentaje_cobro}
                    placeholder="ej:10" 
                    onChange={handleChangePrestacion('porcentaje_cobro')}
                  >
                    <option value="">Seleccione</option>
                    <option value="50">50%</option>
                    <option value="100">100%</option>
                  </select>
                </div>
                
                <div className="col-xs-12 text-center">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={resetPrestacion}>Cerrar</button>
                  <input type="submit" className="btn btn-secondary" value="Agregar"/>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {/*<button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-secondary" id="guardarUsuario"> <i className="fa fa-paper-plane-o" /> Guardar</button>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const columnsGrupo   = [
    {
      name: 'Tipo de Médico',
      cell: row => (
        <>
          {row.tipo_medico_nombre}
        </>
      )	
    },
    
    {
      name: 'Rut Médico',
      cell: row => (
        <>
          {row.rut_medico} 
        </>
      )	
    },
    {
      name: 'Es Anestesista',
      cell: row => (
        <>
          {(row.cirujano_anestesista === 1) ? "Sí" : "No"}
        </>
      )	
    },
    {
      name: 'Acción',
      cell: row => (
        <>
          <button 
            className="btn btn-sm btn-danger" 
            onClick={quitarMedico}
            title="Eliminar"
            id={row.id}
            rut={row.rut_medico}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      )	
    },


  ];

  const modalGrupoMedico =  (
    <>
      <div className="modal fade" id="ModalGrupoMedico" tabIndex={-1} role="dialog" aria-labelledby="ModalGrupoMedicoLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
              <h5 className="modal-title" id="ModalGrupoMedicoLabel"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <form onSubmit={handleSubmit(agregarMedico)}>
                <div className="form-group">
                  <SelectLista
                    titulo="Seleccione tipo Médico"
                    nombreCampo="tipo_medico"
                    name="tipo_medico"
                    handleChange={handleChangeMedico('tipo_medico')}
                    valor={nuevoMedico && nuevoMedico.tipo_medico}
                    datos={listaTiposMedico}
                    obligatorio={true}
                  />
                </div>
                <div className="form-check form-check-inline" id="anestesista_checkbox" style={{display: nuevoMedico.tipo_medico === 1 ? 'block' : 'none' }}>
                  <label className="form-check-label">Anestesista</label>
                  <input
                      name="cirujano_anestesista"
                      id="anestesista_checkbox_label"
                      type="checkbox"
                      className="form-check-input"
                      checked={nuevoMedico && nuevoMedico.cirujano_anestesista}
                      onChange={handleChangeMedico('cirujano_anestesista')}
                  />
                  <small style={{color: "red"}}>{MensajeError["cirujano_anestesista"]}</small>
                </div>
                <div className="form-group">
                  <label>Rut Médico <span style={{color:"red"}}>*</span></label>
                  <input 
                    name="rut_medico"
                    type="text" 
                    placeholder="11111111-1" 
                    className="form-control"
                    value={nuevoMedico && nuevoMedico.rut_medico}
                    onChange={handleChangeMedico('rut_medico')}
                  />
                  <small style={{color: "red"}}>{MensajeError["rut_medico"]}</small>
                </div>

                
                <div className="col-xs-12 text-center">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={resetMedico}>Cerrar</button>
                  <input type="submit" className="btn btn-secondary" value="Agregar"/>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel panel-default panel-wide">
                <div className="panel-body">                      
                
                    <h1>Intervención Quirúrgica</h1>

                    <h3>Prestaciones</h3>
                    <div className="col-xs-12 text-right">
                        <button 
                          type="button" 
                          className="btn btn-sm btn-secondary" 
                          title="Agregar Prestación" 
                          //onClick={this.handleAgregarPrestacion}
                          data-toggle="modal" 
                          data-target="#ModalPrestaciones"
                        >
                            <FontAwesomeIcon icon={faPlus}/>
                        </button>
                    </div>
                    <div className="">
                      <div className="col-xs-12">
                        <Tabla 
                          datos={values.prestaciones} 
                          columns={columns}
                        />
                      </div>
                    </div>

                    <h3>Datos del grupo médico</h3>
                    <div className="col-xs-12 text-right">
                        <button 
                          type="button" 
                          className="btn btn-sm btn-secondary" 
                          title="Agregar integrante de grupo médico" 
                          
                          data-toggle="modal" 
                          data-target="#ModalGrupoMedico"
                        >
                            <FontAwesomeIcon icon={faUserPlus}/>
                        </button>
                    </div>
                    <div className="">
                      <div className="col-xs-12">
                        <Tabla 
                          datos={values.grupo_medico} 
                          columns={columnsGrupo}
                        />
                      </div>
                    </div>
                    
                    <div className="col-xs-12 row">
                        
                        <div className="col-xs-6 text-left">
                            <button className="btn btn-danger" onClick={volver}>Regresar</button>
                        </div>
                        <div className="col-xs-6 text-right">
                            <button className="btn btn-secondary" onClick={continuar}>Continuar</button>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        {modalPrestacion}
        {modalGrupoMedico}
    </>
    
  )

}

export default IntervencionQuirurgica;
