import React, { Component } from 'react';
import Swal from 'sweetalert2';
import {authenticate} from '../components/Funciones';
import LoginGoogle from '../components/LoginGoogle';

const redireccionSuccess = '/';
class Login extends Component {

    state = {
        form: {
            email: '',
            password: '',
        }
    }

    handleChange = async(e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]:e.target.value,
            }
        });
    }

    
    render (){
        return (
            <div className="row" id="formulario-login">
                <div className="login-form col-md-4 col-md-offset-4 col-sm-12 col-xs-12">
                    <div className="panel panel-default">
                    <div className="panel-body">
                        <form id="loginForm" method="post">
                        <h2 className="text-center">Iniciar Sesión</h2>
                        <div className="form-group">
                            <label htmlFor="correo_login">Correo Electrónico</label>
                            <input 
                                type="email" 
                                name="email" 
                                id="correo_login" 
                                className="form-control requerido" 
                                placeholder="Ingrese su correo electrónico" 
                                value={this.state.email} 
                                onChange={this.handleChange}
                            />
                            <span className="col-xs-12 text-danger" style={{display: 'none'}}>
                            <span style={{color: 'red', fontSize: 13}}>
                                <i className="fa fa-info-circle" /> &nbsp; El correo electrónico es obligatorio
                            </span>
                            <br />
                            </span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pass_login">Contraseña</label>
                            <input 
                                type="password" 
                                name="password" 
                                id="pass_login" 
                                className="form-control requerido" 
                                placeholder="Ingrese su contraseña"
                                onChange={this.handleChange}
                            />
                            <span className="col-xs-12 text-danger" style={{display: 'none'}}>
                            <span style={{color: 'red', fontSize: 13}}>
                                <i className="fa fa-info-circle" /> &nbsp; La contraseña es obligatoria
                            </span>
                            <br />
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="text-left">
                                {/*<a href="registro">
                                <u>Regístrate</u>
                                </a>*/}
                            </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="text-right">
                                <a href="recupera">
                                <u>¿Olvidó su contraseña?</u>
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop: 30}}>
                            <a className="btn btn-secondary btn-block" id="btnLogin" onClick={this.login}>
                            Ingresar
                            </a>
                        </div>
                        <div className="form-group">
                            <LoginGoogle/>
                            {/* <a className="btn btn-danger btn-block" href="#">
                            <i className="fa fa-google" /> Iniciar Sesión con <b>Google</b>
                            </a> */}
                        </div>
                        <div className="clearfix">
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    // Proceed with user login
    login = ()  =>{       
        // intentando realizar login, si es sxitoso redirecciona al usuario, sino se muestra un error
        authenticate(this.state.form).then(user => {
            window.location.href = redireccionSuccess;
        }).catch(errorMessage => {
            let message = 'Usuario o Clave inválidos';
            Swal.fire(message);
        });
    }

}

export default Login
