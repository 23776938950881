import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPlus, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getPrestaciones } from './Funciones';
import Tabla from './Tabla';
import SelectLista from './SelectLista';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import Select2 from "./Select2";

const OtrasPrestaciones = ({
  values,
  nextStep,
  prevStep,
  handleChange,
  handleAgregaProcedimiento,
  handleQuitarProcedimiento
}) => {

  const { handleSubmit, formState: { errors } } = useForm();

  const[listaPrestaciones, setListaPrestaciones] = useState([]);
  const [nuevoProcedimiento, setNuevoProcedimiento] = useState({
    id: '',
    codigo: '',
    nombre: '',
    cantidad: '',
  });


  useEffect(() => {
    getlistaPrestaciones();
    
  }, []);

  const getlistaPrestaciones = () => {
    getPrestaciones({
      filial: values.filial
    })
    .then(json => setListaPrestaciones(json.datos));
  }

  const handleChangePrestacion = input => e => {
    let valor = e.target ? e.target.value : e.value;
    
    if(input === 'id'){
      buscaPrestacionEnLista(valor);
    }
    setNuevoProcedimiento({
			...nuevoProcedimiento,
			[input] : valor
		}); 
  }

  const buscaPrestacionEnLista = (id)  => {
    
		let datos = listaPrestaciones;
    
		for ( let index = 0; index < datos.length; index++) {
			if( datos[index].id === parseInt(id)) {
				nuevoProcedimiento.nombre = datos[index].nombre_prestacion;
				nuevoProcedimiento.codigo = datos[index].codigo;
			}
		}
	  return true;
  }

  const agregarPrestacion = data => {
    let error = false;
    let codigo = '';
    let mensaje = '';
    if(
        nuevoProcedimiento.id === '' ||
        nuevoProcedimiento.codigo === '' ||
        nuevoProcedimiento.nombre === '' ||
        nuevoProcedimiento.cantidad === ''
    ){
      error = true;
      mensaje = 'Debe ingresar todos los campos obligatorios';

      Swal.fire(mensaje);
      return false;
    }


    // values.otros_procedimientos.map((prestacion) => {
    //   if(prestacion.id === nuevoProcedimiento.id){
    //     error = true;
    //     codigo = prestacion.codigo;
    //     mensaje = 'Ya existe la prestación con el código ' + codigo;
    //   }
    // });

    if(error){
      Swal.fire(mensaje)
      return false;
    }
    handleAgregaProcedimiento(nuevoProcedimiento);
    resetPrestacion();
  }

  const quitarPrestacion = e => {
    let id = parseInt(e.currentTarget.id);
    Swal.fire({
      title: 'Está seguro de eliminar la prestación seleccionada?',
      text: "no podrá deshacer los cambios!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        handleQuitarProcedimiento(id);
        Swal.fire(
          'Eliminado!',
          'La prestación ha sido eliminada de la lista.',
          'success'
        )
      }
    })
  }

  const resetPrestacion = () => {
    setNuevoProcedimiento({
      id: '',
      codigo: '',
      nombre: '',
      cantidad: '',
    });
  }

  const continuar = e => {
    e.preventDefault();
    let mensaje = '';
    let errores = false;

    if(values.prestaciones.length === 0 && values.otros_procedimientos.length === 0){
      mensaje = 'Debe ingresar al menos una prestación';
      errores =true;
    }

    if(errores){
      Swal.fire(mensaje);
      return false;
    }   

    nextStep();
  }

  const back = e => {
    e.preventDefault();
    prevStep();
  }

  const columns   = [
    {
      name: 'Código',
      cell: row => (
        <>
          {row.codigo}
        </>
      )	
    },
    
    {
      name: 'Descripción',
      cell: row => (
        <>
          {row.nombre} 
        </>
      )	
    },
    {
      name: 'Cantidad',
      cell: row => (
        <>
          {row.cantidad}
        </>
      )	
    },
    {
      name: 'Acción',
      cell: row => (
        <>
          <button 
            className="btn btn-sm btn-danger" 
            onClick={quitarPrestacion}
            title="Eliminar"
            id={row.id}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      )	
    },

  ];
  const modalPrestacion =  (
    <>
      <div className="modal fade" id="ModalPrestaciones" tabIndex={-1} role="dialog" aria-labelledby="ModalPrestacionesLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
              <h5 className="modal-title" id="ModalPrestacionesLabel"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <form onSubmit={handleSubmit(agregarPrestacion)}>
                <div className="form-group">
                  <Select2
                    titulo="Seleccione Prestación" 
                    nombreCampo="id" 
                    name="id"
                    datos={listaPrestaciones} 
                    modal="" 
                    handleChange={handleChangePrestacion('id')} 
                    valor={nuevoProcedimiento && nuevoProcedimiento.id}
                    obligatorio={true}
                  />
                </div>
                <div className="form-group">
                  <label>Código <span style={{color:"red"}}>*</span></label>
                  <input 
                    name="codigo"
                    type="text" 
                    placeholder="código prestacion" 
                    className="form-control"
                    value={nuevoProcedimiento && nuevoProcedimiento.codigo}
                    onChange={handleChangePrestacion}
                    //{...register("rut", {required: true})} 
                  />
                </div>
                <div className="form-group">
                  <label>Descripción prestación <span style={{color:"red"}}>*</span></label>
                  <input 
                    name="nombre"
                    type="text" 
                    placeholder="descripcion de la prestacion" 
                    className="form-control"
                    value={nuevoProcedimiento && nuevoProcedimiento.nombre}
                    onChange={handleChangePrestacion}
                    //{...register("rut", {required: true})} 
                  />
                </div>
                <div className="form-group">
                  <label>Cantidad <span style={{color:"red"}}>*</span></label>
                  <input 
                    name="cantidad"
                    type="number" 
                    placeholder="1000" 
                    className="form-control"
                    value={nuevoProcedimiento && nuevoProcedimiento.cantidad}
                    onChange={handleChangePrestacion('cantidad')}
                  />
                </div>
                
                <div className="col-xs-12 text-center">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={resetPrestacion}>Cerrar</button>
                  <input type="submit" className="btn btn-secondary" value="Agregar"/>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {/*<button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-secondary" id="guardarUsuario"> <i className="fa fa-paper-plane-o" /> Guardar</button>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="panel panel-default panel-wide">
          <div className="panel-body">
              <h1>Otras Prestaciones</h1>

              <h3>Prestaciones</h3>
              <div className="col-xs-12 text-right">
                <button 
                  type="button" 
                  className="btn btn-sm btn-secondary" 
                  title="Agregar Prestación" 
                  //onClick={this.handleAgregarPrestacion}
                  data-toggle="modal" 
                  data-target="#ModalPrestaciones"
                >
                    <FontAwesomeIcon icon={faPlus}/>
                </button>
              </div>
              <div className="">
                <div className="col-xs-12">
                  <Tabla 
                    datos={values.otros_procedimientos} 
                    columns={columns}
                  />
                </div>
              </div>

              <div className="col-xs-12 row">
                  <div className="col-xs-6 text-left">
                      <button className="btn btn-danger" onClick={back}>Regresar</button>
                  </div>
                  <div className="col-xs-6 text-right">
                      <button className="btn btn-secondary" onClick={continuar}>Continuar</button>

                  </div>

              </div>
          </div>
        </div>
      </div>
      {modalPrestacion}
    </div>
  )
    
}

export default OtrasPrestaciones;
