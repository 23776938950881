import React from 'react'

const Footer = () => {
    return (
        <footer className="footer" style={{position: 'fixed', bottom: 0, width: '100%', zIndex: 9}}>
            <div className="container">
                <div className="row">
                <div className="col-md-3 col-xs-12 text-center">
                </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
