import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faPencilAlt, faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import {getListaPermisos, getPerfiles, guardarPerfil, guardarPermisosPerfil} from '../components/Funciones';
import SelectLista from '../components/SelectLista';
import ListaCheck from '../components/ListaCheck';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';


const Perfiles = () => {
    const [perfil, setPerfil] = useState({
      id: '',
      nombre : '',
      estado: ''
    });
    const [permisos, setPermisos] = useState([]);
    const [readyPermisos, setReadyPermisos] = useState(false);
    const [perfiles, setPerfiles] = useState([]);
    const [datos, setDatos] = useState({
      perfil : '',
      permisos: []
    });
    const { handleSubmit } = useForm();

    const estados = [
      {'id' : 1, 'nombre':'Activo', 'value' : 1, 'label' : 'Activo'},
      {'id' : 2, 'nombre':'Inactivo', 'value' : 2, 'label' : 'Inactivo'}
    ];

    

    const guardarPrf = data => {
      guardarPerfil(perfil)
      .then(response => {
        Swal.fire(response.msj);
        if(!response.error){
          resetPerfil();
          getListaPerfiles();
        }
      });
    }

    const resetPerfil = () => {
      setPerfil({
        id: '',
        nombre : '',
        estado: ''
      });
    }

    const resetDatos = () => {
      setDatos({
          perfil : '',
          permisos: []
      });
    }

    useEffect(() => {
      getListaPerfiles();
    }, []);
    
    const getListaPerfiles = () => {
        getPerfiles().then( (json) => {
          setPerfiles(json.datos);
        });
    }

    let todos_permisos = [];
    const getPermisos = (perfil_id) => {
        getListaPermisos(perfil_id)
        .then(json => {
            setPermisos(json.datos);
            const arreglo_permisos = Object.values(json.datos).map(key => {
                // Se filtra solo los registros cuyo campo activo sea 1.
                const filtrado = key.filter(permiso => permiso.activo === 1 );
                //Se obtiene solo el campo id
                let permisos_filtrados = Object.values(filtrado).map(key => key.id);
                todos_permisos.push(...permisos_filtrados);
          });

            setDatos({
            ['perfil']: perfil_id,
            ['permisos']: todos_permisos
            })

           setReadyPermisos(true);

        });
    }

    const handleChange = input => e => {
      if(input === 'perfil'){
        if(e.value === ''){
          setPermisos([]);
          setReadyPermisos(false);
        }else{
          getPermisos(e.value);
        }
      }  
      setDatos({
        ...datos,
        [input] : e.value
      })
    }

    const handleChangeCheckbox = input => {

       let nuevos_permisos = {};
       let item_permiso = {};
       let aux = [];

       Object.keys(permisos).map(key =>{
            nuevos_permisos[key] = [];
            permisos[key].map(item=>{
                item_permiso = item;
                if(item.id === parseInt(input.target.value)){
                    item_permiso = {
                        'id': item.id,
                        'nombre': item.nombre,
                        'activo': (input.target.checked) ? 1 : 0
                    };
                }
                if(item_permiso.activo === 1){
                    aux.push(item_permiso.id);
                }
                nuevos_permisos[key].push(item_permiso);
            });
       });
        setPermisos(nuevos_permisos);

        const inputName = "permisos";

        setDatos({
        ...datos,
        [inputName] : aux
      })
    }

    const handleChangePerfil = input => e => {
      setPerfil({
        ...perfil,
        [input] : e.value
      })
    }

    const handleSubmitPermisos = data => {
        guardarPermisosPerfil(datos).then(response => {
            if(!response.error){
                Swal.fire(response.msj);
                resetDatos();
                setReadyPermisos(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    

    const botonera = (
      <div className="float-right">
        {/* Button trigger modal */}
        <button type="button" className="btn btn-sm btn-secondary" data-toggle="modal" data-target="#ModalPerfiles" title="Crear">
          <FontAwesomeIcon icon={faPlus}/>
        </button>
      </div>
    );

    const formulario = (
      <form onSubmit={handleSubmit(guardarPrf)}>
          <div className="col-xs-12 text-left">
              <div className="form-group">
              <label htmlFor="Perfil">Nombre</label>
              <input 
                type="text" 
                className="form-control requerido" 
                name="nombre"  
                placeholder="Nombre del perfil" 
                onChange={handleChangePerfil('nombre')}
                value={perfil && perfil.nombre}
              />
              </div>
          </div>
          <div className="col-xs-12 text-left">
              <SelectLista 
                nombreCampo="estado" 
                datos={estados} 
                modal="" 
                handleChange={handleChangePerfil('estado')} 
                valor={perfil && perfil.estado}
              />
          </div>
          <div className="col-xs-12">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
              <button type="submit" className="btn btn-secondary" id="guardarPerfil"> <i className="fa fa-paper-plane-o" /> Guardar</button>
          </div>
      </form>
    );

    const modalPerfil = (
      <>
        {/* Modal */}
        <div className="modal fade" id="ModalPerfiles" tabIndex={-1} role="dialog" aria-labelledby="ModalPerfilesLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
                <h5 className="modal-title" id="ModalPerfilesLabel"></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body row">
                {formulario}
              </div>
              <div className="modal-footer">
               
              </div>

            </div>
          </div>
        </div>
      </>

    );

    //listado de perfiles debe mostrarse tras evento de cambio de selector y buscar los permisos checkeados
    const listaPermisos = (
      <div className="col-xs-12" id="listado_permisos">
        <div className="row">
          {Object.keys(permisos).map(key => {
            return (
              <>
              <div className='col-xs-12 col-lg-4 col-md-4 col-sm-6' style={{minHeight: 400}} key={'cont_'+key}>
                <h3>{key}</h3>
                <ListaCheck key={key} lista={permisos[key]} nombre="permiso" handleChange={handleChangeCheckbox}/>
              </div>
              </>
            )
          }) }
        </div>
      </div>
        
    )

    //retorna la vista de perfiles
    return (
        <>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="panel panel-default panel-wide">
                  <div className="panel-body">
                      <h3><FontAwesomeIcon icon={faUsers}/> Perfiles</h3>
                    <div className="row">
                      
                      <div className="col-lg-6 col-md-6">
                        <SelectLista
                            titulo="Perfil"
                            nombreCampo="perfil"
                            accionCrear={false}
                            accionEditar={false}
                            valor={datos.perfil}
                            datos={perfiles}
                            modal="ModalPerfiles"
                            handleChange={handleChange('perfil')}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6"></div>
                        { readyPermisos && (  
                          <div className="col-lg-12 col-md-12">
                            
                            {listaPermisos}
                            <div className="col-xs-12 text-right">
                                <div className="form-group">
                                    <button type="submit" onClick={handleSubmitPermisos} className="btn btn-secondary" id="guardar">
                                        <FontAwesomeIcon icon={faPaperPlane}/>
                                          Guardar
                                    </button>
                                </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
              </div>
          </div>
          {modalPerfil}
        </>
    )
    
}

export default Perfiles;
