import React, { useState } from 'react';
import DataTable from 'react-data-table-component-with-filter';

const Tabla = ({ datos, columns, titulo, serverSide = true }) => {
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    
    const paginacionOpciones = {
        rowsPerPageText: 'Filas por página',
        rangeSeparator: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const dataTabla = (datos && datos.data) ? datos.data : datos;
    return (
        <div className="table-responsive">
            {datos && (
                
                <DataTable
                    columns={columns}
                    data={dataTabla}
                    title={titulo && titulo}
                    highlightOnHover
                    pagination
                    paginationServer={serverSide}
                    paginationTotalRows={datos.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={paginacionOpciones}
                    // fixedHeader
                    // fixedHeaderScrollHeight="600px"
                    className="table-condensed"
                    onChangePage={page => setPage(page)}
                    noDataComponent="No se ha encontrado información"
                    responsive={true}
                    wrap={true}
                />
            ) }
        </div>
    )
}

export default Tabla
