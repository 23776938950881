import axios from 'axios';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';

const {REACT_APP_API_URL}  = process.env;

const baseUrl = `${REACT_APP_API_URL}`;
const config = {
    headers: { Authorization: 'bearer ' + localStorage.getItem("userToken"), 'Content-Type': 'application/json' }
};

export  const getMe = () => {
        return new Promise((resolve, reject) => {
            //const config = { headers: { Authorization: 'bearer ' + localStorage.getItem("userToken") } };
            axios.get(`${baseUrl}me`, config).then((response) => {
                localStorage.setItem('usuario', response.data.nombre);
                localStorage.setItem('email', response.data.email);
                resolve(response.data);
            })
            .catch((error) => Errores(error));
        })
    }

export const hasUser = () => {
    return localStorage.getItem("userToken");
};

export const authenticate = (data) => {
    return new Promise((resolve, reject) => {
        //validando campos
        let error = null;
        if (!data.email || !data.password)
            error = "Debe ingresar su email y password";
        else if (!validateEmail(data.email))
            error = "Porfavor ingrese un email valido";
        else if (data.password.length < 4)
            error = "la password es muy corta";

                // Some error?
        if (error) {
            reject(error);
            return;
        }

        axios.post(`${baseUrl}login`, data).then((response) => {
            localStorage.setItem("userToken", response.data.access_token);
            localStorage.setItem("permisos", response.data.usuario.permisos);
            localStorage.setItem("super", response.data.usuario.super);

            resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const authenticateGoogle = (data) => {
    console.log(data.email);
    return new Promise((resolve, reject) => {
        //validando campos
        let error = null;
        if (!data.email)
            error = "Debe ingresar su email y password";
        else if (!validateEmail(data.email))
            error = "Porfavor ingrese un email valido";

        // Some error?
        if (error) {
            reject(error);
            return;
        }

        axios.post(`${baseUrl}loginGoogle`, data).then((response) => {
            console.log(response);
            localStorage.setItem("userToken", response.data.access_token);
            localStorage.setItem("permisos", response.data.usuario.permisos);
            localStorage.setItem("super", response.data.usuario.super);

            resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const logout = () => {
    localStorage.clear();
    <Redirect to="/login"/>;
    //window.location.href = '/login';
}

    // Simple email validate function
export const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

/**
 * Método para obtener menu de navegacion de usuario
 * @returns
 */
export const getMenu = () => {
    console.log(hasUser);
    if (!hasUser){
        //llamar al endpoint que devuelve el menu
        return <div className="collapse navbar-collapse navbar-ex1-collapse">
            <ul className="nav navbar-nav">
                <li className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                        Administración <b className="caret"></b>
                    </a>
                    <ul className="dropdown-menu">
                        <li><a href="/administracion/usuarios">Usuarios</a></li>
                        <li><a href="/administracion/perfiles">Perfiles</a></li>
                        <li><a href="/administracion/filiales">Filiales</a></li>
                    </ul>
                </li>

                {/*enlace cerrar sesion*/}
                <li>
                    <a  onClick={logout}>
                        nombre usuario &nbsp;<i className="fa fa-sign-out logoff" title="Cerrar Sesión" ></i>
                    </a>
                </li>
            </ul>
        </div>
    } else {
        return <></>
    }
}

/**
 *
 * endpoints usuario
 */

export const getTablaUsuarios = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'usuarios/datatable', config)
        .then((response) => {
            return resolve(response.data);
        }).catch(error => Errores(error));
    });
}

/**
 * Tabla de perfiles de usuario
 * @returns
 */
export const getTableAsignaciones = (id) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'usuarios/asignaciones/datatable',{
          "id": id
        }, config)
        .then((response) => {
            return resolve(response.data);
        }).catch(error => Errores(error));
    });
}

/**
 * Método para guardado de usuarios nuevos y existentes
 * @param {*} data
 * @returns
 */
export const guardarUsuario = (data) => {

    return new Promise((resolve, reject) => {
        //validando campos
        let error = null;

        if (!data.email || (data.id== '' && !data.password) )
            error = "Debe ingresar su email y password";
        else if (!validateEmail(data.email))
            error = "Porfavor ingrese un email valido";
        else if (data.id== '' && data.password.length < 4)
            error = "la password es muy corta";

        // Some error?
        if (error) {
            Swal.fire(error);
            return;
        }


        axios.post(`${baseUrl}usuarios/save`,data,  config).then((response) => {
            resolve(response.data);
        })
        .catch((error) => Errores(error));
    });
}

export const getUsuarios = () => {
  return new Promise((resolve, reject) => {
      axios.get( baseUrl + 'usuarios/lista', config)
      .then((response) => {
          return resolve(response.data);
      }).catch((error) => Errores(error));
  });
}

export const guardarAsignacion = (data) => {

  return new Promise((resolve, reject) => {
      //validando campos
      let error = null;

      if (!data.usuario || !data.perfil || !data.filial )
          error = "Debe ingresar todos los campos requeridos";

      // Some error?
      if (error) {
          Swal.fire(error);
          return;
      }


      axios.post(`${baseUrl}usuarios/guarda_asignacion_perfil`,data,  config).then((response) => {
          resolve(response.data);
      })
      .catch((error) => Errores(error));
  });
}

export const guardarPermisosPerfil = (data) => {

  return new Promise((resolve, reject) => {
      //validando campos
      let error = null;

      if (!data.perfil)
          error = "Debe ingresar todos los campos requeridos";

      // Some error?
      if (error) {
          Swal.fire(error);
          return;
      }

      axios.post(`${baseUrl}permisos/asignar`,data,  config).then((response) => {
          resolve(response.data);
      })
      .catch((error) => {
          // Errores(error)
          console.log(error);
      });
  });
}

export const getPermisos = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'permisos/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const getListaPermisos = (id) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'permisos/lista',{
          perfil: id
        }, config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

/**
 * Solicitud para obtener perfiles
 * @returns
 */
export const getPerfiles = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'perfiles/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const guardarPerfil = (data) => {
  return new Promise((resolve, reject) => {
    //validando campos
    let error = null;
    console.log(data);
    if (!data.nombre)
        error = "Debe ingresar todos los campos requeridos";

    // Some error?
    if (error) {
        Swal.fire(error);
        return;
    }

    axios.post(`${baseUrl}perfiles/guardar`,data,  config).then((response) => {
        resolve(response.data);
    })
    .catch((error) => Errores(error));
  });
}

/**
 * Solicitud para obtener filiales
 * @returns
 */
export const getFiliales = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'filiales/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch(error => Errores(error));
    });
}

/**
 * Obtener datatable de filiales
 * @returns
 */
export const getTablaFiliales = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'filiales/datatable', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

/**
 * Guardar dato de filial nueva o existente
 * @param {*} data
 * @returns
 */
 export const guardarFilial = (data) => {
    return new Promise((resolve, reject) => {
        //validando campos
        let error = null;

        if (!data.nombre )
          error = "Debe ingresar el nombre de la filial";
        else if( !data.nombre_establecimiento)
          error = "Debe ingresar el nombre del establecimiento";
        else if( !data.rut_establecimiento)
          error = "Debe ingresar el rut del establecimiento";
        else if ( !data.tipo_carga_id)
          error = "Debe seleccionar un tipo de carga";

        // Some error?
        if (error) {
            Swal.fire(error);
            return;
        }

        axios.post(`${baseUrl}filiales/guardar`,data,  config)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => Errores(error));
    });
}

/**
 * Obtener datos de una filial especifica
 * @param {*} data
 * @returns
 */
export const getFilial = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'filiales/buscar', data ,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

/**
 * Obtener estados
 * @param {*} data
 * @returns
 */
 export const getEstados = (tipo) => {

    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'pam/estados/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) =>console.log(error));
    });
}

/**
 * Tipos de PAM
 * @returns
 */
 export const getTiposPAm = () => {
    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'pam/tipos/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch(error => Errores(error));
    });
}

/**
 * Obtener isapres
 * @param {*} data
 * @returns
 */
 export const getIsapres = (tipo) => {

    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'aseguradores/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) =>Errores(error));
    });
}

export const getPrestaciones = (datos) => {

    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/prestaciones/lista', datos, config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) =>Errores(error));
    });
}
/**
 *
 * @param {*} datos
 * @returns
 */
export const getTiposMedico = () => {

    return new Promise((resolve, reject) => {
        axios.get( baseUrl + 'pam/tipos_medico/lista', config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) =>Errores(error));
    });
}

/**
 * Obtener datos de un medico
 * @param {*} data
 * @returns
 */
export const getdatosMedico = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/medico/buscar',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}


/**
 * Envio de clave provisoria
 * @param {*} data
 * @returns
 */
export const EnviaRecuperarClave = (data) => {
    return new Promise((resolve, reject) => {
        //validando campos
        let error = null;
        if (!data.email)
            error = "Debe ingresar su email";
        else if (!validateEmail(data.email))
            error = "Porfavor ingrese un email válido";

        // Some error?
        if (error) {
            Swal.fire(error);
            return;
        }


        axios.post(`${baseUrl}recuperar_clave`,data,  config).then((response) => {
            resolve(response.data);
        })
        .then(json => {
            //let message = json.msj;
            //Swal.fire(message);
        })
        .catch((error) => {
            console.log(error);
        });
    });
}

/**
 * méotodo para guardar informacion del pam digitado
 * @param {.} data
 * @returns
 */
export const GuardarDatosPam = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/guardar',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const EliminarDatosPam = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/eliminar',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

export const ExportarPAM = (data) => {
    return new Promise((resolve, reject) => {
        let error = null;
        axios.post( baseUrl + 'pam/exportPam',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

// export const ExportarPAM = (data) => {
    
//     return new Promise((resolve, reject) => {
//         console.log(data);
//         //validando campos
//         let error = null;
//         axios.get(baseUrl + 'exportPam',data,config).then((response) => {
//             resolve(response.data);
//             console.log(resolve(response.data));
//         })
//         .catch((error) => Errores(error));
//     });
// }

/**
 * Buscar informacion de un pam digitado
 * @param {*} data
 * @returns
 */
export const buscarPam = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/buscar',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}

 /**
  * Datatable pam digitados
  * @param {*} data
  * @returns
  */
export const getDatatablePam = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/datatable',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}
 /**
  * Devuelve informacion de los pam digitados
  * @param {*} data
  * @returns
  */
export const getReportesPam = (data) => {
    return new Promise((resolve, reject) => {
        axios.post( baseUrl + 'pam/reporte',data,config)
        .then((response) => {
            return resolve(response.data);
        }).catch((error) => Errores(error));
    });
}


export const Errores = (error) => {
  if(error.response.status == 401){
    localStorage.clear();
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Se ha cerrado la sesión'
    });

    window.location.href='login';
  }else if(error.response.status == 502){
      Swal.fire('Ocurrió un error Interno en el Servidor');

  }
}
