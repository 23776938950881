import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasUser } from '../components/Funciones';

/**
 * Check si se tiene un usuario autenticado.
 * si no tiene redirecciona al usuario a ruta /login 
 */
const RequireAuth = (component, user) => {
    if (hasUser())
        return component;
    return  <Redirect to="/login"/>;
};

export default RequireAuth;
