import React, { useState } from 'react'
import GoogleLogin from 'react-google-login'
import { authenticateGoogle } from './Funciones';
import Swal from 'sweetalert2';

const redireccionSuccess = '/';
const {REACT_APP_GOOGLE_CLIENT_ID}  = process.env;
const GoogleClientID = `${REACT_APP_GOOGLE_CLIENT_ID}`;


const LoginGoogle = () => {
  
  const responseGoogle = (response) => {
    
    if(response.profileObj){
      
      loginWithGoogle(response.profileObj);
    }
  }

  const loginWithGoogle = (data)  =>{ 
            // intentando realizar login, si es exitoso redirecciona al usuario, sino se muestra un error
        authenticateGoogle(data).then(user => {
            window.location.href = redireccionSuccess;
        }).catch(errorMessage => {
          console.log(errorMessage);
            let message = 'Error al iniciar sesión';
            Swal.fire(message);
        });
    }

  return (
    <div>
      <GoogleLogin
        clientId={GoogleClientID}
        render={renderProps => (
          <button className="btn btn-danger btn-block" onClick={renderProps.onClick} disabled={renderProps.disabled}>Iniciar Sesión con Google</button>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  )
}

export default LoginGoogle
