import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPencilAlt, faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from 'react';
import Tabla from '../components/Tabla';
import { Loading } from '../components';
import {getFilial, getTablaFiliales, guardarFilial} from '../components/Funciones';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';


const Filiales = () => {
  
  const [isReady, setIsReady] = useState(false);
  const [filiales, setFiliales] = useState([]);
  const { handleSubmit, formState: { errors } } = useForm();
  const [filial, setFilial] = useState({
    id: '',
    nombre: '',
    tipo_carga_id: '',
    rut_establecimiento: '',
    nombre_establecimiento: '',
  });
  const titulo_tabla = "Lista de Filiales";
  const columns   = [
	{
			name: 'ID',
			selector: 'id',
			sortable: true
	},
	{
			name: 'Nombre',
			selector: 'nombre',
			sortable: true,
			grow: 2
	},
	{
			name: 'Tipo Carga',
			selector: 'tipo_carga',
			sortable: true
	},
	{
		name: 'Accion',
		cell: row => (
			<button 
				className="btn btn-sm btn-info" 
				onClick={handleEditar}
				title="Editar"
				id={row.id}
				data-toggle="modal" data-target="#ModalFiliales"
			>
					<FontAwesomeIcon icon={faPencilAlt}/>
			</button>
		)
			
	}
	];

  //metodos
  const onSubmit = data => {
		data.id = filial ? filial.id : '';
		guardar(filial);
		//document.querySelector('#ModalUsuarios').modal("hide");
	};

  const guardar  = (data) => {
    guardarFilial(data)
		.then(response => {
      Swal.fire(response.msj);
			if(!response.error){
				resetFilial();
				getFiliales();
			}
			
    }).catch(errorMessage => {
        alert(errorMessage);
    });
  }

	const handleChange = (input) => {
		setFilial({
			...filial,
			[input.target.name] : input.target.value
		})
	}

  const resetFilial = () => {
    setFilial({
      id: '',
      nombre: '',
      tipo_carga_id: '',
      rut_establecimiento: '',
      nombre_establecimiento: '',
    });
  } 
  /**
   * Estado del componente
   */
  useEffect(() => {
	  getFiliales();
  }, []);

  const getFiliales = () => {
    getTablaFiliales().then(json => {
  		setFiliales(json);
	  	setIsReady(true);
	  });
  }

  const handleEditar = (e) => {
		let id = parseInt(e.currentTarget.id);
    let data = {
      'id': id

    };
    buscarId(data);
	}

  const buscarId = (data) => {
    let tmpFilial = null;
    getFilial(data).then(json => {
      setFilial(json.datos);
    });

    return tmpFilial;
	}

	const mdlFormulario = (
		<>	
			{/* Modal */}
			<div className="modal fade" id="ModalFiliales" tabIndex={-1} role="dialog" aria-labelledby="ModalFilialesLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
							<h5 className="modal-title" id="ModalFilialesLabel"></h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body text-left">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group">
									<label htmlFor="nombre">Nombre</label>
									<input 
										type="text" 
										className="form-control" 
										name="nombre" 
										placeholder="Ingrese nombre de filial" 
										value={filial.nombre}
										onChange={handleChange}
									/>
								</div>
                <div className="form-group">
									<label htmlFor="nombre_establecimiento">Nombre Establecimiento</label>
									<input 
										type="text" 
										className="form-control" 
										name="nombre_establecimiento" 
										placeholder="Ingrese nombre del establecimiento" 
										value={filial.nombre_establecimiento}
										onChange={handleChange}
									/>
								</div>
                <div className="form-group">
									<label htmlFor="rut_establecimiento">Rut Establecimiento</label>
									<input 
										type="text" 
										className="form-control" 
										name="rut_establecimiento" 
										placeholder="Ingrese nombre de filial" 
										value={filial.rut_establecimiento}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="tipo_carga_id">Tipo de Carga</label>
									<select 
										className="form-control"
										name="tipo_carga_id"
										value={filial.tipo_carga_id}
                    onChange={handleChange}
									>
										<option value="">Seleccione ...</option>
										<option value="1">Formulario</option>
										<option value="2">Automática</option>
									</select>
								</div>
                <div className="col-xs-12 text-center">
									<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={resetFilial}>Cerrar</button>
									<input type="submit" className="btn btn-secondary"/>
								</div>
							</form>
						</div>
						<div className="modal-footer">
                
						</div>
					</div>
				</div>
			</div>
    </>
	)

  const botonera = (
		<div className="text-right">
			{/* Button trigger modal */}
			<button 
        type="button" 
        className="btn btn-sm btn-secondary" 
        data-toggle="modal" 
        data-target="#ModalFiliales"
        title="Crear"
      >
				<FontAwesomeIcon icon={faPlus}/>
			</button>
		</div>
	)

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="panel panel-default panel-wide">
        <div className="panel-body">
            <h3><FontAwesomeIcon icon={faUsers}/> Filiales</h3>
            <div className="row">
              {botonera}
              {
                filiales != [] ?
                  <Tabla datos={filiales} columns={columns} handleEditar={handleEditar} titulo={titulo_tabla}/>
                :
                <Loading/>
                }
            </div>
        </div>
      </div>
      {mdlFormulario}
    </div>
  )
}

export default Filiales
