import React from 'react';

export default () => (
    <fieldset className="respuesta" style={{marginBottom: 500}}>
        <div className="row">
            <div className="col-md-8 col-md-offset-2 col-xs-12">
            <div className="panel panel-default panel-wide">
                <div className="panel-body">
                <div className="form-group col-xs-12">
                    <h1>404</h1>
                    <h4 style={{textAlign: 'center'}}>Estimado Usuario, la dirección a la que ha ingresado no es válida</h4>
                    <p className="contenido" style={{textAlign: 'justify'}} />
                </div>
                </div>
                <div className="row">
                <div className="form-group col-xs-12 text-center">
                    <hr />
                    <a className="btn btn-secondary" href="/">VOLVER AL SITIO</a>
                </div>
                </div>
            </div>
            </div>
        </div>
    </fieldset>

    );
