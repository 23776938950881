import React from "react";
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Usuarios from "../pages/Usuarios";
import Perfiles from "../pages/Perfiles";
import RequireAuth from "./RequireAuth";
import PageNotFound from "../pages/PageNotFound";
import Filiales from "../pages/Filiales";
import DashboardPam from "../pages/DashboardPam";
import PamForm from '../components/PamForm';
import RecuperarClave from "../pages/RecuperarClave";
import AsignacionPermisos from "../pages/AsignacionPermisos";
import Reportes from "../pages/Reportes";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route  exact path="/login" component={Login}/>
        <Route  exact path="/recupera" component={RecuperarClave}/>
        {RequireAuth(<Route  exact path="/" component={Dashboard}/>)}
        {RequireAuth(<Route  exact path="/admin/usuarios" component={Usuarios}/>)}
        {RequireAuth(<Route  exact path="/admin/perfiles" component={Perfiles}/>)}
        {RequireAuth(<Route  exact path="/admin/filiales" component={Filiales}/>)}
        {RequireAuth(<Route  exact path="/admin/asignacion" component={AsignacionPermisos}/>)}
        {RequireAuth(<Route  exact path="/formulario/pam" component={PamForm}/>)}
        {RequireAuth(<Route  exact path="/formulario/pam/:pam" component={PamForm}/>)}
        {RequireAuth(<Route  exact path="/dashboard/pam" component={DashboardPam}/>)}
        {RequireAuth(<Route  exact path="/reportes" component={Reportes}/>)}
        <Route  exact path="/404" component={PageNotFound}/>
        <Route exact path='*' component={PageNotFound}/>

      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
