import React, { Component } from 'react'
import { getEstados, getFiliales, getIsapres, getTiposPAm } from './Funciones';
import SelectLista from './SelectLista';
import {validate} from 'rut.js';

class DatosInicialesPam extends Component {
    constructor(props){
        super(props);
        this.state = {
            filiales: [],
            isapres: [],
            tiposPam: [],
            listaEstados: [],
        }
    }

    continue = e => {
        e.preventDefault();
        
        let campos_obligatorios = [
            'filial',
            'rut_paciente',
            //'fecha_nacimiento_pac',
            //'email_pac',
            'nombre_paciente',
            // 'fecha_admision2',
            'isapre',
            'rut_medico',
            'nombre_medico',
            'especialidad_medico',
            'tipo_actividad',
            'fecha_actividad2',
            'tipo_horario',
            'diagnostico'
        ];
        if(this.props.values.id !== ''){
            campos_obligatorios.push('id_cuenta');
            //campos_obligatorios.push('codigo_pam');
        }

        if(!this.props.validarCampos(campos_obligatorios)){
            return false;
        }
        if(!validate(this.props.values.rut_paciente)){
            return false;
        }

        this.props.nextStep();
    }

    listaFiliales = () => {
        getFiliales().then(json => this.setState({filiales: json.datos}));
    }

    listaIsapres = () => {
        getIsapres().then(json => this.setState({isapres: json.datos}));
    }

    listaTiposPam = () => {
      getTiposPAm().then(json => this.setState({tiposPam: json.datos}));
    }

    getEstadosPam = () => {
        getEstados()
        .then(json => this.setState({ listaEstados :json.datos}));
    }

    componentWillMount() {
        this.listaFiliales();
        this.listaIsapres();
        this.listaTiposPam();
        this.getEstadosPam();
        
    }
    
    render() {
        const { values, handleChange, handleblur } = this.props;
        return (
          <>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="panel panel-default panel-wide">
                <div className="panel-body">
                    <h1>Programa de Atención Médica Digital</h1>
                    {/*Debiesen salir las filiales que tiene el usuario asociadas */}
                    <div className="col-xs-12 row">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <SelectLista
                                  titulo="Filial de Atención"
                                  nombreCampo="filial" 
                                  datos={this.state.filiales} 
                                  modal="" 
                                  handleChange={handleChange('filial')} 
                                  valor={values.filial}
                                  obligatorio={true}
                                  msj_error = {values.error["filial"]}
                                />
                            </div>
                        </div>
                        { values.id != '' && (
                          <>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <SelectLista
                                      titulo="Estado" 
                                      nombreCampo="estado" 
                                      datos={this.state.listaEstados} 
                                      modal="" 
                                      handleChange={handleChange('estado')} 
                                      valor={values.estado}
                                      msj_error = {values.error["estado"]}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label for="folio"> 
                                      Folio {/* <span style={{color:"red"}}>*</span> */}
                                    </label>
                                    <input 
                                        type="text" 
                                        name="folio" 
                                        className="form-control" 
                                        onChange={handleChange('folio')}
                                        value={values.folio}
                                        placeholder="Número de folio"
                                    />
                                    <small style={{color: "red"}}>{values.error["folio"]}</small>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="id_cuenta"> ID cuenta <span style={{color:"red"}}>*</span></label>
                                    <input 
                                        type="text" 
                                        name="id_cuenta" 
                                        className="form-control" 
                                        onChange={handleChange('id_cuenta')}
                                        value={values.id_cuenta}
                                        placeholder="ID Cuenta"
                                    />
                                    <small style={{color: "red"}}>{values.error["id_cuenta"]}</small>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="codigo_pam"> Número Protocolo Operatorio <span style={{color:"red"}}></span></label>
                                    <input 
                                        type="text" 
                                        name="codigo_pam" 
                                        className="form-control" 
                                        onChange={handleChange('codigo_pam')}
                                        value={values.codigo_pam}
                                        placeholder="Código PAM"
                                    />
                                    <small style={{color: "red"}}>{values.error["codigo_pam"]}</small>
                                </div>
                            </div>
                            
                        </>
                        )}
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="fecha_admision2"> Fecha Ingreso Paciente</label>
                                <input
                                    type="date"
                                    name="fecha_admision2"
                                    className="form-control"
                                    onChange={handleChange('fecha_admision2')}
                                    value={values.fecha_admision2}
                                />
                                <small style={{color: "red"}}>{values.error["fecha_admision2"]}</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <h3>Datos del paciente</h3>
                    </div>
                                      
                    
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                        <div className="form-group">
                            <label htmlFor="rut_paciente"> Rut <span style={{color:"red"}}>*</span></label>
                            <input 
                                type="text" 
                                name="rut_paciente" 
                                className="form-control" 
                                onChange={handleChange('rut_paciente')}
                                value={values.rut_paciente}
                                placeholder="1111111-1"
                            />
                            <small style={{color: "red"}}>{values.error["rut_paciente"]}</small>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                      <div className="form-group">
                          <label htmlFor="nombre_paciente"> Nombre Completo Paciente <span style={{color:"red"}}>*</span></label>
                          <input 
                              type="text" 
                              name="nombre_paciente" 
                              className="form-control" 
                              onChange={handleChange('nombre_paciente')}
                              value={values.nombre_paciente}
                              placeholder="Nombre paciente"
                          />
                          <small style={{color: "red"}}>{values.error["nombre_paciente"]}</small>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="fecha_nacimiento_pac2"> Fecha nacimiento </label>
                            <input 
                                type="date" 
                                name="fecha_nacimiento_pac2"
                                className="form-control"
                                onChange={handleChange('fecha_nacimiento_pac2')}
                                value={values.fecha_nacimiento_pac2}
                            />
                            <small style={{color: "red"}}>{values.error["fecha_nacimiento_pac2"]}</small>
                        </div>
                    </div>
                    <div className="col-xs-12"></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                
                        <div className="form-group">
                            <SelectLista 
                              titulo="Isapre"
                              nombreCampo="isapre" 
                              datos={this.state.isapres} 
                              modal="" 
                              handleChange={handleChange('isapre')} 
                              valor={values.isapre}
                              obligatorio={true}
                              msj_error = {values.error["isapre"]}
                            />
                        </div>
                    </div>

                    <div className="col-xs-12">
                        <h3>Datos del profesional tratante y actividad</h3>
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="rut_medico"> RUT Médico Tratante <span style={{color:"red"}}>*</span></label>
                            <input 
                                type="text" 
                                name="rut_medico" 
                                className="form-control" 
                                onChange={handleChange('rut_medico')}
                                value={values.rut_medico}
                                placeholder="1111111-1"
                                onBlur={handleblur('rut_medico')}
                            />
                            <small style={{color: "red"}}>{values.error["rut_medico"]}</small>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="nombre_medico"> Nombre Completo Médico Tratante <span style={{color:"red"}}>*</span></label>
                            <input 
                                type="text" 
                                name="nombre_medico" 
                                className="form-control" 
                                onChange={handleChange('nombre_medico')}
                                value={values.nombre_medico}
                                placeholder="Nombre Médico"
                            />
                            <small style={{color: "red"}}>{values.error["nombre_medico"]}</small>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="especialidad_medico"> Especialidad Médico Tratante <span style={{color:"red"}}>*</span></label>
                            <input 
                                type="text" 
                                name="especialidad_medico" 
                                className="form-control" 
                                onChange={handleChange('especialidad_medico')}
                                value={values.especialidad_medico}
                                placeholder="Especialidad Médico"
                            />
                            <small style={{color: "red"}}>{values.error["especialidad_medico"]}</small>
                        </div>
                    </div>

                    
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">                    
                        <div className="form-group">
                            
                            <SelectLista 
                              titulo="Tipo de actividad"
                              nombreCampo="tipo_actividad" 
                              datos={this.state.tiposPam} 
                              modal="" 
                              handleChange={handleChange('tipo_actividad')} 
                              valor={values.tipo_actividad}
                              obligatorio={true}
                              msj_error = {values.error["tipo_actividad"]}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">                
                        <div className="form-group">
                            <label htmlFor="fecha_actividad2"> Fecha actividad <span style={{color:"red"}}>*</span></label>
                            <input 
                                type="date" 
                                name="fecha_actividad2"
                                className="form-control" 
                                onChange={handleChange('fecha_actividad2')}
                                value={values.fecha_actividad2}
                            />
                            <small style={{color: "red"}}>{values.error["fecha_actividad2"]}</small>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                    
                        <div className="form-group">
                            <label htmlFor="tipo_horario"> Tipo de Horario <span style={{color:"red"}}>*</span></label>
                            <select 
                                type="text" 
                                name="tipo_horario" 
                                className="form-control" 
                                onChange={handleChange('tipo_horario')}
                                value={values.tipo_horario}
                            >
                                <option value="seleccione">Seleccione tipo horario</option>
                                <option value="H">Hábil</option>
                                <option value="I">Inhábil</option>
                            </select>
                            <small style={{color: "red"}}>{values.error["tipo_horario"]}</small>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                    
                        <div className="form-group">
                            <label htmlFor="diagnostico"> Diagnóstico <span style={{color:"red"}}>*</span></label>
                            <textarea 
                                type="text" 
                                name="diagnostico" 
                                className="form-control" 
                                onChange={handleChange('diagnostico')}
                                value={values.diagnostico}
                                rows="4"
                            >
                            </textarea>
                            <small style={{color: "red"}}>{values.error["diagnostico"]}</small>
                        </div>
                    </div>
                    
                    
                    <div className="col-xs-12 text-right">
                        <button className="btn btn-secondary" onClick={this.continue}>Continuar</button>

                    </div>
                </div>
              </div>
            </div>
          </>
        )
    }

}

export default  DatosInicialesPam;
