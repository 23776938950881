import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { EnviaRecuperarClave } from '../components/Funciones';


const RecuperarClave = () => {

  const [datos, setDatos] = useState({
    email: ''
  });

  const handleChange = (input) => {
    setDatos({
        ...datos,
        [input.target.name] : input.target.value
    })
}

  const enviarDatos = (e) => {
    e.preventDefault();
    EnviaRecuperarClave(datos).then(json => {
      Swal.fire(json.msj);
      limpiarForm();
    });
    
  }

  const limpiarForm = () => {
    setDatos({
      email: ''
    })
  }

  return (
    <div className="row" id="formulario-content">
        <div className="login-form col-md-4 col-md-offset-4 col-sm-12 col-xs-12">
            <div className="panel panel-default">
            <div className="panel-body">
                <form onSubmit={enviarDatos} id="formulario-recupera">
                <h2 className="text-center">Recuperar Clave</h2>
                <div className="form-group">
                    <label htmlFor="correo_login">Correo Electrónico</label>
                    <input 
                        type="email" 
                        name="email"
                        className="form-control requerido" 
                        placeholder="Ingrese su correo electrónico"
                        value={datos.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group" style={{marginTop: 30}}>
                    <button type="submit" className="btn btn-secondary btn-block" id="btnLogin" >
                    Enviar
                    </button>
                </div>
                <div className="form-group" style={{marginTop: 30}}>
                    <a className="btn btn-danger btn-block" href="/">
                    Volver
                    </a>
                </div>
                
                <div className="clearfix">
                </div>
                </form>
            </div>
            </div>
        </div>
    </div>
  )
}

export default RecuperarClave;
