import React, { useState, useEffect, Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Select, {createFilter} from 'react-select';
import { FixedSizeList as List } from "react-window";
import AsyncSelect from "react-select/async";


const height = 35;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}


const Select2 = ({titulo,nombreCampo, accionCrear, accionEditar, datos, valor, modal, handleChange, obligatorio, msj_error}) => {
    const [opciones, setOpciones] = useState([]);

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(datos);
        }, 1000);
    }

    /*const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">Text</span>
            </components.NoOptionsMessage>
        );
    };

    const msgStyles = {
        background: "skyblue",
        color: "white"
    };*/


    return (
        <>
            <div className="form-group">
                <label htmlFor={nombreCampo}>
                    {titulo ? titulo :nombreCampo} {obligatorio && (<span style={{color:"red"}}>*</span>)} &nbsp;
                    { accionCrear && (
                        <>
                            <FontAwesomeIcon icon={faPlus} style={{color: 'limegreen', cursor: 'pointer'}} title="Crear" data-toggle="modal" data-target={'#' + modal}/>
                            &nbsp;&nbsp;
                        </>
                    )}
                    { accionEditar && (
                        <>
                            <FontAwesomeIcon icon={faPencilAlt} style={{cursor: 'pointer'}} title="Editar"/>
                        </>
                    )}
                </label>

                {/*<Select
                    components={{ MenuList }}
                    onChange={handleChange}
                    options={datos}
                    name={nombreCampo}
                    placeholder="Seleccione ..."
                    noOptionsMessage={ () => {
                        return 'No se encontró información';
                    }}
                    value = {
                        datos.filter(option =>
                            option.value === valor)
                    }
                    filterOption={createFilter({ ignoreAccents: false })}

                />*/}

                <AsyncSelect
                    components={{ MenuList }}
                    onChange={handleChange}
                    cacheOptions
                    defaultOptions={datos}
                    name={nombreCampo}
                    loadOptions={loadOptions}
                    placeholder="Seleccione ..."
                    // loadingMessage={() => "Buscando"}
                    // noOptionsMessage={() => "No se encontró información"}
                    // styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                    value = {
                        datos.filter(option =>
                            option.value === valor)
                    }
                    filterOption={createFilter({ ignoreAccents: false })}
                />

                <small style={{color: "red"}}>{msj_error}</small>

            </div>
        </>
    )
}

export default Select2;
