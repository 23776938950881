import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faPencilAlt, faPlus, faSave , faTrash} from "@fortawesome/free-solid-svg-icons";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import SelectLista from '../components/SelectLista';
import { getFiliales, getPerfiles, getTableAsignaciones, getUsuarios, guardarAsignacion, guardarUsuario } from '../components/Funciones';
import Tabla from '../components/Tabla';


let datos_asignacion = {
    usuario: '',
    perfil: '',
    filial: '',
    estado: '',
}
const AsignacionPermisos = () => {

  //propiedades
  const [perfilesUsuario, setperfilesUsuario] = useState([]);
  const [asignacion, setAsignacion] =useState(datos_asignacion);
  const [perfiles, setPerfiles] = useState([]);
	const [filiales, setFiliales] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
  const { handleSubmit, formState: { errors } } = useForm();

  const columns = [
		{
			name: 'ID',
			selector: 'id',
			sortable: true
		},
		{
				name: 'Usuario',
				selector: 'usuario',
				sortable: true,
				grow: 2
		},
		{
				name: 'Perfil',
				selector: 'perfil',
				sortable: true
		},
		{
				name: 'Filial',
				selector: 'filial',
				sortable: true
		},
    {
			name: 'Accion',
			cell: row => (
				<>
          <button 
            className="btn btn-sm btn-danger" 
            onClick={quitarPerfil}
            title="Quitar Perfil"
            id={row.id}
            usuario={row.id_usuario}
            perfil={row.id_perfil}
            filial={row.id_filial}
            estado={0}
            type="button"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
			)
				
		}
	]

  //metodos
  const onSubmit = data => {
		data.usuario = asignacion ? asignacion.usuario : '';
		guardar(asignacion);
	};

  //metodo para guardar informacion
	const guardar = (data) => {
    data.estado = data.estado == '' ? 1 : 0;
    
		guardarAsignacion(data)
		.then(response => {
			if(!response.error){
				Swal.fire(response.msj);
				resetAsignacion();
				getAsignaciones();
			}
    }).catch(errorMessage => {
        alert(errorMessage);
    });
	}

  const resetAsignacion = () => {
    setAsignacion({
      usuario: '',
      perfil: '',
      filial: '',
      estado: '',
    });
  }

  const getListaPerfiles = () => {
    getPerfiles().then( (json) => {
    setPerfiles(json.datos);
    });
  }
	
  const getListaFiliales = () => {
    getFiliales().then( (json) => {
      setFiliales(json.datos);
    });
  }

  const getListaUsuarios = () => {
    getUsuarios().then( json => {
      setUsuarios(json.datos);
    });
  }


  const getAsignaciones = (id) => {
    getTableAsignaciones(id).then(json => setperfilesUsuario(json));
  }

  const handleChange = (input) => e => {
    const valor = e.value;
    if(input == "usuario"){
      //obtener asignaciones
      getAsignaciones(valor);
    }

		setAsignacion({
			...asignacion,
			[input] : valor
		})
	}

  const handleEditar = (e) => {
		let id = parseInt(e.currentTarget);
		//setUsuario(buscarId(usuarios, id));
	}

  const quitarPerfil = (e) => {
    
    const obj = e.currentTarget.attributes;
    const datos = {
      usuario: obj.usuario.value,
      perfil: obj.perfil.value,
      filial: obj.filial.value,
      estado: obj.estado.value,
    };
    
    Swal.fire({
      title: "Está seguro?",
      text: "Confirma remover el perfil del usuario seleccionado?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        guardar(datos);
      }else{
        return false;
      }
    });
  }

  //ciclo de vida: cuando el componente esta recien cargado
	useEffect(() => {
		getListaPerfiles();
    getListaFiliales();
    getListaUsuarios();
	}, []);


  return (
    <>
			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div className="panel panel-default panel-wide">
							<div className="panel-body">
									<h3><FontAwesomeIcon icon={faUsers}/> Perfiles y Asignación</h3>
									<form onSubmit={handleSubmit(onSubmit)}>                  
                    <div className="col-xs-4">
                      <SelectLista 
                        nombreCampo="usuario" 
                        obligatorio={true} 
                        acciones={false} 
                        datos={usuarios} 
                        modal="" 
                        handleChange={handleChange('usuario')}
                        valor={asignacion.usuario}
                      />
                    </div>
                    <div className="col-xs-4">
                      <SelectLista 
                        nombreCampo="perfil" 
                        obligatorio={true} 
                        acciones={false} 
                        datos={perfiles} 
                        modal="" 
                        handleChange={handleChange('perfil')}
                        valor={asignacion.perfil}  
                      />
                    </div>

                    <div className="col-xs-4">
                      <SelectLista 
                        nombreCampo="filial" 
                        obligatorio={true} 
                        acciones={false} 
                        datos={filiales} 
                        modal="" 
                        handleChange={handleChange('filial')}
                        valor={asignacion.filial}
                      />
                    </div>

                    <div className="col-xs-12 text-right">
                      <button type="submit" className="btn btn-sm btn-secondary" title="Asignar">
                      Asignar  <FontAwesomeIcon icon={faSave} />  
                      </button>
                    </div>
                    <div className="col-xs-12">
                      { perfilesUsuario && (
                        <Tabla datos={perfilesUsuario} columns={columns} handleEditar={handleEditar} titulo="Perfiles Asignados"/>
                      )}
                    </div>
                  </form>
							</div>
					
					</div>
			</div>
		</>
  )
}

export default AsignacionPermisos
