import {logout, hasUser } from "../../components/Funciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const Navigation = () => {

    const permisos = localStorage.getItem('permisos');
    const is_super = localStorage.getItem('super')  == 's' ? true : false;
    /**
     * Request user logout and redirect to /
     */
    const cerrarSesion = () => {
        logout();
        window.location.href = '/login';
    }
    
    return (
        <header id="header" className="header">
            <nav className="navbar navbar-fixed-top">
                <nav className="header-bottom navbar navbar-default" role="navigation" style={{'borderTop': '40px solid #099'}}>
                    <div className="container scrolled-in-wrapped">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle visible-xs visible-sm" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                                <span className="sr-only">Desplegar navegación</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <a href="/" className="navbar-brand">
                                <img alt="Red Salud - Somos CChC" src="/logo_redsalud.png" style={{'maxWidth': '250px', 'width': '100%'}} className="hidden-xs" />
                                <img alt="Red Salud - Somos CChC" src="/logo_redsalud.png" style={{'maxWidth': '232.33px', 'width': '100%'}} className="visible-xs-block"/>
                            </a>
                        </div>
                        { hasUser() &&
                          <div className="collapse navbar-collapse navbar-ex1-collapse">
                            <ul className="nav navbar-nav">
                              { (
                                  permisos.includes('usuarios') || 
                                  permisos.includes('perfiles') || 
                                  permisos.includes('filiales') ||
                                  permisos.includes('perfiles_asignacion') ||
                                  is_super 
                                ) && (
                                    <>
                                      <li className="dropdown">
                                          <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                              Administración <b className="caret"></b>
                                          </a>
                                          <ul className="dropdown-menu">
                                              { (permisos.includes('usuarios') || is_super ) && (<li><a href="/admin/usuarios">Usuarios</a></li>)}
                                              { (permisos.includes('perfiles') || is_super ) && (<li><a href="/admin/perfiles">Perfiles</a></li>)}
                                              
                                              { (permisos.includes('perfiles') || is_super ) && (<li><a href="/admin/asignacion">Perfiles Asignación</a></li>)}
                                              { (permisos.includes('filiales') || is_super ) && (<li><a href="/admin/filiales">Filiales</a></li>)}
                                          </ul>
                                      </li>
                                    </>
                                )}

                                {(permisos.includes('dashboard_pam') || is_super) && (
                                    <>
                                      {/* grupo de rutas de PAM */}
                                      <li className="dropdown">
                                          <a href="/dashboard/pam">
                                              Dashboard PAM
                                          </a> 
                                      </li>
                                    </>
                                  )
                                }

                                {/* grupo de rutas de PAM */}
                                {(permisos.includes('formulario_pam') || is_super) && (
                                    <>
                                      <li className="dropdown">
                                          <a href="/formulario/pam">
                                              Formulario PAM
                                          </a>
                                      </li>
                                    </>
                                  )
                                }
                                
                                {/* Reportes */}
                                {(permisos.includes('reportes') || is_super) && (
                                    <>
                                      <li className="dropdown">
                                          <a href="/reportes">
                                              Reportes
                                          </a>
                                      </li>
                                    </>
                                  )
                                }

                                {/*enlace cerrar sesion*/}
                                <li> 
                                  <a  onClick={cerrarSesion}>
                                      Salir &nbsp; <FontAwesomeIcon icon={faSignOutAlt} color='#ad2c2c'/>
                                  </a>
                                </li>

                            </ul>
                          </div>
                        }
                    </div>
                </nav>
            </nav>
        </header>
    )
}

export default Navigation;