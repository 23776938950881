import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import DatosInicialesPam from './DatosInicialesPam';
import { buscarPam, getdatosMedico, GuardarDatosPam } from './Funciones';
import IntervencionQuirurgica from './IntervencionQuirurgica';
import Observaciones from './Observaciones';
import OtrasPrestaciones from './OtrasPrestaciones';
import {validate, clean, format} from 'rut.js';

class PamForm extends Component {
  
  componentDidMount() {
    const {pam} = this.props.match.params;
    if(pam){
      this.buscarInfoPam(pam);
      this.state.es_editar = true
      
    }
  }
  
  state = {
    step: 1,
    id: '',
    estado: '',
    email_pac: '',
    isapre: '',
    rut_paciente: '',
    nombre_paciente: '',
    fecha_nacimiento_pac: '',
    fecha_nacimiento_pac2: '',
    fecha_admision: '',
    fecha_admision2: '',
    profesional_tratante: '',
    rut_medico: '',
    id_cuenta: '',
    nombre_medico: '',
    especialidad_medico: '',
    tipo_actividad: '',
    fecha_actividad: '',
    fecha_actividad2: '',
    tipo_horario: '',
    diagnostico: '',
    prestaciones: [],
    grupo_medico: [],
    otros_procedimientos: [],
    observaciones: '',
    acepto_terminos: '',
    filial: '',
    error: {},
  };

  buscarInfoPam = (id) => {
    
    buscarPam({
      pam: id
    }).then( response => {
      if(response.error){
        window.location.href='/404';
      }
      const data = response.datos;

      const formatDate = (dateStr) => {
        if(dateStr === null || dateStr === "null"){
          return null;
        }
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
      };

      this.setState({
        step: data.step,
        id: data.id,
        estado: data.estado,
        email_pac: data.email_pac,
        isapre: data.isapre,
        rut_paciente: data.rut_paciente,
        nombre_paciente: data.nombre_paciente,
        fecha_nacimiento_pac: formatDate(data.fecha_nacimiento_pac),
        fecha_nacimiento_pac2: data.fecha_nacimiento_pac,
        fecha_admision: formatDate(data.fecha_admision),
        fecha_admision2: data.fecha_admision,
        profesional_tratante: data.profesional_tratante,
        rut_medico: data.rut_medico,
        nombre_medico: data.nombre_medico,
        especialidad_medico: data.especialidad_medico,
        tipo_actividad: data.tipo_actividad,
        fecha_actividad: formatDate(data.fecha_actividad),
        fecha_actividad2: data.fecha_actividad,
        tipo_horario: data.tipo_horario,
        diagnostico: data.diagnostico,
        prestaciones: data.prestaciones,
        grupo_medico: data.grupo_medico,
        otros_procedimientos: data.otros_procedimientos,
        observaciones: data.observaciones,
        acepto_terminos: data.acepto_terminos,
        filial: data.filial,
        codigo_pam: data.codigo_pam,
        id_cuenta: data.id_cuenta,
        folio: data.folio,
        generar_pdf: false
      });
    });
  }

  // Proceed to next step
  nextStep = () => {
    const { step, tipo_actividad } = this.state;
    //let suma = step == 1 && tipo_actividad == 1 ? 1 : 2;
    
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step, tipo_actividad } = this.state;
    //let resta = step == 3 && tipo_actividad == 1 ? 1 : 2;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = input => e => {
    let valor =  e.target ? e.target.value : e.value;

    const formatDate = (dateStr) => {
      if(dateStr === null || dateStr === "null"){
        return null;
      }
      const [year, month, day] = dateStr.split('-');
      return `${day}/${month}/${year}`;
    };
    
    if(input === 'otras_prestaciones' || input === 'acepto_terminos'){
      valor =  e.target.checked ? 1 : 0;
    }

    if(input === 'generar_pdf'){
      valor =  e.target.checked ? true : false;
    }

    if(input === 'rut_paciente' || input === 'rut_medico'){
      valor = format(valor);
    }

    if(input === 'fecha_admision2' || input === 'fecha_actividad2'|| input === 'fecha_nacimiento_pac2'){
      this.setState({ [input.slice(0, -1)]: formatDate(valor) });
    }

    this.setState({ [input]: valor });
  };

  handleAgregaPrestacion = datos =>  {
    
    this.state.prestaciones.push(datos);
    this.setState({ ...this.state.prestaciones, datos });
  }

  handleAgregaMedico= datos =>  {
    this.state.grupo_medico.push(datos);
    this.setState({ ...this.state.grupo_medico, datos });
  }
  
  handleAgregaProcedimiento= datos =>  {
    this.state.otros_procedimientos.push(datos);
    this.setState({ ...this.state.otros_procedimientos, datos });
  }

  handleQuitarPrestacion = (id) =>  {
    const newPrestaciones = this.state.prestaciones.filter( (prestacion) => parseInt(prestacion.id) !== id );
    this.setState({ prestaciones: newPrestaciones});
  }

  handleQuitarMedico = (id) =>  {
    const newMedico = this.state.grupo_medico.filter( (medico) => medico.rut_medico !== id );
    this.setState({ grupo_medico: newMedico});
  }

  handleQuitarProcedimiento = (id) =>  {
    const newProcedimiento = this.state.otros_procedimientos.filter( (procedimiento) => parseInt(procedimiento.id) !== id );
    this.setState({ otros_procedimientos: newProcedimiento});
  }

  handleblur = input => e => {
    let valor =  e.target.value;
    if( input === 'rut_medico'){
      this.buscarMedico(valor);
    }

  }

  buscarMedico = (rut) => {
    getdatosMedico({
        rut_medico: rut
    }).then(response => {

      const data = response.datos;
      

      this.setState({ 
        nombre_medico: data.nombre_medico ? data.nombre_medico : '',
        especialidad_medico: data.especialidad_medico ? data.especialidad_medico : '',
      });
      
    });
  }

  render() {
    
    const { step } = this.state;
    const {
      id, 
      estado,
      email_pac,
      isapre,
      rut_paciente,
      nombre_paciente,
      fecha_nacimiento_pac,
      fecha_nacimiento_pac2,
      fecha_admision,
      fecha_admision2,
      profesional_tratante,
      rut_medico,
      nombre_medico,
      especialidad_medico,
      tipo_actividad,
      fecha_actividad,
      fecha_actividad2,
      tipo_horario,
      diagnostico,
      prestaciones,
      grupo_medico,
      otros_procedimientos,
      observaciones,
      acepto_terminos,
      filial,
      codigo_pam,
      id_cuenta,
      folio,
      error,
      generar_pdf
    } = this.state;
    
    const values = { 
      id,
      estado,
      email_pac,
      isapre,
      rut_paciente,
      nombre_paciente,
      fecha_nacimiento_pac,
      fecha_nacimiento_pac2,
      fecha_admision,
      fecha_admision2,
      profesional_tratante,
      rut_medico,
      nombre_medico,
      especialidad_medico,
      tipo_actividad,
      fecha_actividad,
      fecha_actividad2,
      tipo_horario,
      diagnostico,
      prestaciones,
      grupo_medico,
      otros_procedimientos,
      observaciones,
      acepto_terminos,
      filial,
      codigo_pam,
      id_cuenta,
      folio,
      error,
      generar_pdf
    };

    const handleValidar = (obligatorios) => {
      let errores = {};
      let formularioValido = true;
      obligatorios.map((campo) => {
          if(values[campo] === '' || values[campo] === null){
              formularioValido = false;
              errores[campo] = 'Este campo es obligatorio';
          }
          //validar si el rut del paciente es inválido
          if(campo === 'rut_paciente' && !validate(values[campo])){
            formularioValido = false;
              errores[campo] = 'El rut ingresado es inválido';
          }
          if(campo === 'rut_medico' && !validate(values[campo])){
            formularioValido = false;
              errores[campo] = 'El rut ingresado es inválido';
          }
      });
      
      this.setState({error: errores});
   
      return formularioValido;
    }

    switch (step) {
      case 1:
        return (
          <DatosInicialesPam
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            handleblur={this.handleblur}
            validarCampos={handleValidar}
          />
        );
      case 2:
        return (
          <IntervencionQuirurgica
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleAgregaPrestacion={this.handleAgregaPrestacion}
            handleQuitarPrestacion={this.handleQuitarPrestacion}
            handleAgregaMedico={this.handleAgregaMedico}
            handleQuitarMedico={this.handleQuitarMedico}
            values={values}
            validarCampos={handleValidar}
          />
        );
      case 3:
        return (
          <OtrasPrestaciones
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleAgregaProcedimiento={this.handleAgregaProcedimiento}
            handleQuitarProcedimiento={this.handleQuitarProcedimiento}
            values={values}
            validarCampos={handleValidar}
          />
        );
      case 4:
        return (
          <Observaciones
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleSubmit={this.guardar}
            validarCampos={handleValidar}
          />
        );
      default:
        return (
          <DatosInicialesPam
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            handleblur={this.handleblur}
            validarCampos={handleValidar}
          />
        );
    }
  }

  guardar = () => {
    Swal.fire({
      title: 'Procesando información...',
      allowOutsideClick: false
    });
    Swal.showLoading();
    GuardarDatosPam(this.state)
    .then(response => {
      
      Swal.fire({
        title: '',
        text: response.msj,
        icon: response.error ? 'error' : 'success',
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let redireccion = '/formulario/pam';
          
          if(this.state.id !== ''){
            redireccion = '/dashboard/pam';
          }

          window.location.href=redireccion;
        }
      })
    });
  }
  
}

export default withRouter(PamForm);