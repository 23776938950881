import React, {useEffect, useState} from 'react';
import SelectLista from '../components/SelectLista';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEstados, getFiliales, getIsapres, getReportesPam } from '../components/Funciones';
import { faUsers, faPencilAlt, faPaperPlane, faPlus, faFilePdf, faFilter, faFileMedical, faFileImport } from "@fortawesome/free-solid-svg-icons";
//limport TarjetaResumen from '../components/TarjetaResumen';

const Reportes = () => {

  const [listaEstados, setListaEstados] =  useState([]);
  const [listaIsapres, setListaIsapres] =  useState([]);
  const [listaFiliales, setListaFiliales] =  useState([]);
  const [isReady, setIsReady] =  useState(false);
  const [isReadyEstado, setIsReadyEstado] =  useState(false);
  const [filtros, setFiltros] =  useState({
    estado: '',
    filial: '',
    isapre: '',
    fecha: '',
  });

  const [reporte, setReporte] =useState({
    total: 0,
    formulario: 0,
    automatica: 0,
    estado: [],
    tipo_actividad: []
  });

  useEffect(() => {
    getEstadosPam();
    getIsapresPam();
    getFilialesLista();
    getReportePam();
  }, []);

  const getIsapresPam = () => {
    getIsapres()
    .then(json => setListaIsapres(json.datos));
  }
  
  const getEstadosPam = () => {
    getEstados()
    .then(json => setListaEstados(json.datos));
  }
  
  const getFilialesLista = () => {
    getFiliales()
    .then(json => setListaFiliales(json.datos));
  }

  const handleChangeFiltros = (input) => e => {
    const valor = e.value;
    setFiltros({
      ...filtros,
      [input] : valor
    });
    
  }

  const getReportePam = () => {

    getReportesPam(filtros).then( json => {
      let datos = json.datos;
      setReporte(json.datos);
      setIsReady(true);
     
    });
  }

  const filtros_reportes = (
    <>
      <div className="col-xs-3 text-left">
          <SelectLista
            titulo="Estado" 
            nombreCampo="estado" 
            datos={listaEstados} 
            modal="" 
            handleChange={handleChangeFiltros('estado')} 
            valor={filtros && filtros.estado}
          />
      </div>
      <div className="col-xs-3 text-left">
          <SelectLista
            titulo="Isapre"  
            nombreCampo="isapre" 
            datos={listaIsapres} 
            modal="" 
            handleChange={handleChangeFiltros('isapre')} 
            valor={filtros && filtros.isapre}
          />
      </div>
      <div className="col-xs-3 text-left">
          <SelectLista
            titulo="Filial"  
            nombreCampo="filial" 
            datos={listaFiliales} 
            modal="" 
            handleChange={handleChangeFiltros('filial')} 
            valor={filtros && filtros.filial}
          />
      </div>
      <div className="col-xs-3 text-left">
          <div className="form-group">
            <label>Fecha</label>
            <input 
              name="fecha" 
              type="date" 
              className="form-control"             
              onChange={handleChangeFiltros('fecha')} 
              valor={filtros && filtros.fecha}
            />
          </div>
      </div>
      <div className="col-xs-12 text-right">
        <button type="button" className="btn btn-sm btn-info" onClick={getReportePam}>
          <FontAwesomeIcon icon={faFilter}/>  Filtrar
        </button>
      </div>
    </>
  );
    
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="panel panel-default panel-wide">
          <div className="panel-body">
              <h3><FontAwesomeIcon icon={faUsers}/> Reportes</h3>
              
              <div className="row">
                {filtros_reportes}
              </div>
          </div>
        </div>
      </div>
      
      {/** Registro total de pam digitados */}
      <div className="col-xs-12 row text-center">
        <div className="panel panel-default panel-wide">
          <div className="panel-body">
            <div className="col-xs-12 row text-center">
              <h3>Resumen General</h3>
            </div>
            <div className="col-lg-4 col-6">
              <div className="small-box bg-success">
                <div className="inner ">
                  <h3>TOTAL</h3>
                  <p className="text-center">{ reporte && reporte.total ? reporte.total : 0}</p>
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon={faUsers} size="2x"/>
                </div>
                <a href="#" className="small-box-footer">Total de PAM digitados al día <i className="fas fa-arrow-circle-right" /></a>
              </div>
            </div>
            
              {/** cantidad de registros por formulario */}
              <div className="col-lg-4 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>FORMULARIO</h3>
                    <p className="text-center">{ reporte && reporte.formulario ? reporte.formulario : 0}</p>
                  </div>
                  <div className="icon">
                    <FontAwesomeIcon icon={faFileMedical} size="2x"/>
                  </div>
                  <a href="#" className="small-box-footer">PAM cargados por formulario <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/** cantidad de registros por carga automática */}
              <div className="col-lg-4 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>AUTOMÁTICA</h3>
                    <p className="text-center">{ reporte && reporte.automatica ? reporte.automatica : 0}</p>
                  </div>
                  <div className="icon">
                    <FontAwesomeIcon icon={faFileImport} size="2x"/>
                  </div>
                  <a href="#" className="small-box-footer">PAM cargados de forma automática <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              
              { isReady && (
                <div className="col-xs-12 row text-center">
                  <h3>Resumen por estado</h3>
                </div>
              )}

              { isReady && reporte.estado.map( ({nombre, cantidad}) => {
                return (
                <>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-primary">
                      <div className="inner">
                        <h3 className="text-uppercase">{ nombre }</h3>
                        <p className="text-center">{ cantidad }</p>
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon icon={faFileImport} size="2x"/>
                      </div>
                      <a href="#" className="small-box-footer">PAM en estado {nombre} <i className="fas fa-arrow-circle-right" /></a>
                    </div>
                  </div>
                </>
                )
              })}

              { isReady && (
                <div className="col-xs-12 row text-center">
                  <h3>Resumen por Tipo de Actividad</h3>
                </div>
              )}
              
              { isReady && reporte.tipo_actividad.map( ({nombre, cantidad}) => {
                return (
                <>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3 className="text-uppercase">{ nombre }</h3>
                        <p className="text-center">{ cantidad }</p>
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon icon={faFileImport} size="2x"/>
                      </div>
                      <a href="#" className="small-box-footer">PAM tipo {nombre} <i className="fas fa-arrow-circle-right" /></a>
                    </div>
                  </div>
                </>
                )
              })}
            </div>
          </div>
        </div>
    </>
  )
}

export default Reportes;
