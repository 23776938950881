import React from 'react';

const ListaCheck = ({lista, nombre ,handleChange, key}) => {
  
  
  return (
    <>
      <ul key={'permisos_'+key}>
        {lista.map(item => {
          return (
            <li key={item.id}>
              {item.nombre} &nbsp;
                <input 
                  type="checkbox" 
                  name={nombre +'[]'}
                  value={item.id}
                  checked={(item.activo === 1)}
                  onChange={handleChange}
                />
            </li>
          )  
        })}
      </ul>
    </>
  )
}

export default ListaCheck
