import React, { Component } from 'react'
import Swal from 'sweetalert2';

export default class Observaciones extends Component {
    continue = e => {
        e.preventDefault();

        let mensaje = '';
        let errores = false;
        
        if(this.props.values.id == '' && !this.props.values.acepto_terminos){
            mensaje = 'Debe aceptar los términos y condiciones';
            errores =true;
        }

        if(errores){
        Swal.fire(mensaje);
        return false;
        }   
        this.props.handleSubmit();
        //this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { values, handleChange, validarGenerarPam} = this.props;
        return (
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="panel panel-default panel-wide">
                        <div className="panel-body">
                            <h1>Observaciones Adicionales</h1>
                            <h3>Datos del paciente</h3>
                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">                
                                <div className="form-group">
                                    <label for="observaciones"> Observaciones</label>
                                    <textarea 
                                        type="email" 
                                        name="observaciones" 
                                        className="form-control" 
                                        onChange={handleChange('observaciones')}
                                        value={values.observaciones}
                                        
                                    >
                                    </textarea>
                                </div>
                            </div>
                            {values.id === '' && (
                                <div className="col-md-offset-3 col-xs-12 col-md-6 text-center">
                                    <p>
                                        Acepto que sea el representante legal de Clínica RedSalud Santiago, quien me represente con su firma en el Programa de Atención Médica que por el presente formulario se emite. Declaro que dicho programa es un fiel reflejo de la información contenida en este formulario, encontrándose su contenido revisado y validado por este declarante.
                                    </p>
                                    Acepto &nbsp;
                                    <input 
                                        type="checkbox" 
                                        name="acepto_terminos" 
                                        onChange={handleChange('acepto_terminos')}
                                        value={values.acepto_terminos}
                                    />
                                </div>
                            )}
                            <div className="col-xs-12 row">
                    <div className="col-xs-6 text-left">
                        <button className="btn btn-danger" onClick={this.back}>Regresar</button>
                    </div>

                    <div className="col-xs-6 text-right">
                        <button className="btn btn-secondary" onClick={this.continue}>Guardar</button>
                    </div>
                        {values.id != '' && (
                            <div className="col-xs-12 text-center">
                                <b>Validar Datos y generar PAM</b> &nbsp;
                                <input
                                    type="checkbox"
                                    name="generar_pdf"
                                    onChange={handleChange('generar_pdf')}
                                    value={values.generar_pdf}
                                /> 
                               
                            </div>
                        )}

                </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
