import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getTablaUsuarios, guardarUsuario } from "../components/Funciones"; 
import { Loading } from '../components';
import Tabla from '../components/Tabla';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';



const Usuarios = () => {
	//establecer estado de datos listos
	const [isReady, setIsReady] = useState(false);
	const titulo_tabla = "Lista de Usuarios";
	//estado para almacenar los datos del usuario actual
	const [usuarios, setUsuarios] = useState([]);
	const [usuario, setUsuario] = useState({
		id: '',
		rut: '',
		nombre: '',
		email: '',
		password: ''
	});

	//estado del modal
	const [show, setShow] = useState(false);
	
	//metodos del modal
	const handleClose = () => setShow(false);
	const abrirModal = () => setShow(true);

	//estados y metodos del form validate
	const { handleSubmit, formState: { errors } } = useForm();
	
	//metodo para enviar detoa del formulario
	const onSubmit = data => {
		data.id = usuario ? usuario.id : '';
		guardar(usuario);
		//document.querySelector('#ModalUsuarios').modal("hide");
	};

	//metodo para guardar informacion
	const guardar = (data) => {
		guardarUsuario(data)
		.then(response => {
			if(!response.error){
				Swal.fire(response.msj);
				resetUsuario();
				getUsuarios();
			}
			
        }).catch(errorMessage => {
            alert(errorMessage);
        });
	}

	//ciclo de vida: cuando el componente esta recien cargado
	useEffect(() => {
		getUsuarios();
	}, []);

	const getUsuarios = () => {
		getTablaUsuarios().then(json => {
			setUsuarios(json);
			setIsReady(true);
		});
	}

	const resetUsuario = () => {
		setUsuario({
			id: '',
			rut: '',
			nombre: '',
			email: '',
			password: ''
		});
	}

	const columns   = [
		{
				name: 'ID',
				selector: 'id',
				sortable: true
		},
		{
				name: 'Nombre',
				selector: 'nombre',
				sortable: true,
				grow: 2
		},
		{
				name: 'Rut',
				selector: 'rut',
				sortable: true
		},
		{
				name: 'Email',
				selector: 'email',
				sortable: true
		},
		{
				name: 'Perfil',
				selector: 'perfil',
				sortable: true
		},
		{
			name: 'Accion',
			cell: row => (
				<>
				<button 
					className="btn btn-sm btn-info" 
					onClick={handleEditar}
					title="Editar"
					id={row.id}
					data-toggle="modal" data-target="#ModalUsuarios"
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</button>
				{/*<button 
					className="btn btn-sm btn-primary" 
					onClick={handleEditar}
					title="Perfiles"
					id={row.id}
					data-toggle="modal" data-target="#ModalAsignaciones"
				>
					<FontAwesomeIcon icon={faUsers} />
        </button>*/}
				</>
			)
				
		}
	];


	const handleEditar = (e) => {
		let id = parseInt(e.currentTarget.id);
		setUsuario(buscarId(usuarios, id));
	}

	const handleChange = (input) => {
		setUsuario({
			...usuario,
			[input.target.name] : input.target.value
		})
	}

	const buscarId = (array, id) => {
		let tmpUsuario =null;
		let datos = array.data;
		for ( let index = 0; index < datos.length; index++) {
			if( datos[index].id === id) {
				tmpUsuario = datos[index]
			}
		}
		return tmpUsuario;
	}

	const botonera = (
		<div className="text-right">
			{/* Button trigger modal */}
			<button type="button" className="btn btn-sm btn-secondary" data-toggle="modal" data-target="#ModalUsuarios">
				<FontAwesomeIcon icon={faPlus}/>
			</button>
		</div>
	)

	const mdlEdit = (
		<>	
			{/* Modal */}
			<div className="modal fade" id="ModalUsuarios" tabIndex={-1} role="dialog" aria-labelledby="ModalUsuariosLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
							<h5 className="modal-title" id="ModalUsuariosLabel"></h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body text-left">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group">
									<label>Rut</label>
									<input 
										name="rut"
										type="text" 
										placeholder="11111111-1" 
										className="form-control"
										value={usuario.rut}
										onChange={handleChange}
										//{...register("rut", {required: true})} 
									/>
								</div>
								<div className="form-group">
									<label>Nombre</label>
									<input 
										name="nombre"
										type="text" 
										placeholder="Nombre" 
										className="form-control"
										value={usuario ? usuario.nombre : ''}
										onChange={handleChange}
										//{...register("nombre", {})} 
									/>
								</div>
								<div className="form-group">
									<label>Correo</label>
									<input
										name="email" 
										type="email" 
										placeholder="email" 
										className="form-control"
										value={usuario ? usuario.email : ''}
										onChange={handleChange}
										//{...register("email", {required: true})} 
									/>
								</div>
								<div className="form-group">
									<label>Contraseña</label>
									<input
										name="password" 
										type="password" 
										placeholder="password" 
										className="form-control"
										defaultValue={usuario ? usuario.password : ''}
										onChange={handleChange}
										//{...register("password", {required: true})} 
									/>
								</div>
								<div className="col-xs-12 text-center">
									<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={resetUsuario}>Cerrar</button>
									<input type="submit" className="btn btn-secondary"/>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							{/*<button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                			<button type="button" className="btn btn-secondary" id="guardarUsuario"> <i className="fa fa-paper-plane-o" /> Guardar</button>*/}
						</div>
					</div>
				</div>
			</div>
    </>
	)

	const mdlAsignacion = (
		<>
			<div className="modal fade" id="ModalAsignaciones" tabIndex={-1} role="dialog" aria-labelledby="ModalAsignacionesLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
							<h5 className="modal-title" id="ModalAsignacionesLabel">Permisos del usuario</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body text-left">
							
						</div>
						<div className="modal-footer">
							{/*<button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                			<button type="button" className="btn btn-secondary" id="guardarUsuario"> <i className="fa fa-paper-plane-o" /> Guardar</button>*/}
						</div>
					</div>
				</div>
			</div>
		</>
	)


	return (
		<>
			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div className="panel panel-default panel-wide">
							<div className="panel-body">
									<h3><FontAwesomeIcon icon={faUsers}/> Usuarios</h3>
									{botonera}
									{
										isReady ?
											<Tabla datos={usuarios} columns={columns} handleEditar={handleEditar} titulo={titulo_tabla}/>
											:
											<Loading />
									}
							</div>
					{mdlEdit}
					{mdlAsignacion}
					</div>
			</div>
		</>
	)
}

export default Usuarios;