import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';

const SelectLista = ({titulo,nombreCampo, accionCrear, accionEditar, datos, valor, modal, handleChange, obligatorio, msj_error}) => {
  const [opciones, setOpciones] = useState([]);
  return (
    <>
      <div className="form-group">
        <label htmlFor={nombreCampo}>
          {titulo ? titulo :nombreCampo} {obligatorio && (<span style={{color:"red"}}>*</span>)} &nbsp; 
          { accionCrear && (
            <>
              <FontAwesomeIcon icon={faPlus} style={{color: 'limegreen', cursor: 'pointer'}} title="Crear" data-toggle="modal" data-target={'#' + modal}/>
              &nbsp;&nbsp;
            </>
          )}
          { accionEditar && (
            <>
              <FontAwesomeIcon icon={faPencilAlt} style={{cursor: 'pointer'}} title="Editar"/>
            </>
          )}
        </label>
        {/* <select 
          name={nombreCampo} 
          id={nombreCampo} 
          className="form-control"
          onChange={handleChange}
          value={valor && valor}
        >
          <option value="">Seleccione {nombreCampo.replace('_', ' ')}</option>
          {datos.map(({id, nombre}) => {
            return <option value={id} key={id}>{nombre}</option>
          }) }
        </select> */}
        
        <Select
          onChange={handleChange}
          //onInputChange={this.handleInputChange}
          options={datos}
          name={nombreCampo}
          //value={1}
          placeholder="Seleccione ..."
          noOptionsMessage={ () => {
            return 'No se encontró información';
          }}
          value = {
            datos.filter(option => 
               option.value === valor)
          }
        />

      <small style={{color: "red"}}>{msj_error}</small>

      </div>
    </>
  )
}

export default SelectLista;
